@import '../theme/variables';

.back-home {
  position: fixed;
  bottom: 16rem;
  //right: 0;
  width: 40px;
  height: 40px;
  background-color: rgba(124, 124, 124, 0.8);
  justify-content: flex-end;
  display: flex;
  align-items: center;
  border-radius: 0 55% 55% 0;
  padding-right: 9px;
  z-index: 99;

  ion-icon {
    color: white;
    font-size: 2.5rem;
  }
}

.loading-class {
  --background: rgba(34, 34, 34, 0.5);
  --spinner-color: #fff;
  font-size: 16px;
  color: #fff;
}

// 覆盖checkbox选中后的背景颜色
.checkbox-ios .checkbox-checked {
  border-color: #32a3e7;
  background-color: #32a3e7;
}

.content-md, .item-md, .item-ios {
  color: var(--ion-color-step-800, #333333);
}

// 字体颜色
.color-white {
  color: var(--ion-background-color, #ffffff) !important;
}

.color-red {
  color: var(--ion-color-wrong, #ee5424) !important;
}

.color-orange {
  color: var(--ion-color-tertiary, #e99532) !important;
}

.color-blue {
  color: var(--ion-color-primary, #32A3E7) !important;
}

.color-sky {
  color: var(--ion-color-sky, #32A3E7) !important;
}

.color-dark {
  color: var(--ion-color-step-800, #3A4149) !important;
}

.color-gray {
  color: var(--ion-font-gray-color, #bbbfc4) !important;
}

.color-shadow {
  color: var(--ion-color-step-600, #666666) !important;
}

.color-smoke {
  color: var(--ion-color-step-400, #999999) !important;
}

.color-green {
  color: var(--ion-color-correct, #37b682) !important;
}

.color-subtitle {
  color: var(--ion-color-subtitle, #666666) !important;
}

.color-shade {
  color: #6885AD !important;
}

.color-secondary {
  color: #8F9599 !important;
}

.color-black {
  color: black;
}

.text-primary {
  color: #32A3E7;
}

.text-default {
  color: #86959F;
}

.text-warning {
  color: #e99532
}

.text-danger {
  color: #ee5424
}

.text-success {
  color: #37b682
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.text-line {
  white-space: normal !important;
}

.bg-primary {
  background-color: #32A3E7;
  color: #fff;
}

.bg-default {
  background-color: #86959F;
  color: #666;
}

.bg-warning {
  background-color: #e99532;
  color: #fff;
}

.bg-danger {
  background-color: #ee5424;
  color: #fff;
}

.bg-success {
  background-color: #37b682;
  color: #fff;
}

.badge-primary {
  font-size: 11px;
  background-color: #32A3E7;
  color: #fff;
  padding: 1px 3px;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 3px;
}

.badge-default {
  font-size: 11px;
  background-color: #86959F;
  color: #666;
  padding: 1px 3px;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 3px;
}

.badge-warning {
  font-size: 11px;
  background-color: #e99532;
  color: #fff;
  padding: 1px 3px;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 3px;
}

.badge-danger {
  font-size: 11px;
  background-color: #ee5424;
  color: #fff;
  padding: 1px 3px;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 3px;
}

.badge-success {
  font-size: 11px;
  background-color: #37b682;
  color: #fff;
  padding: 1px 3px;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 3px;
}

.btn-small {
  height: 29px;
  line-height: 29px;
}

.btn-default {
  height: 42px;
  line-height: 42px;
}

.btn-large {
  height: 48px;
  line-height: 48px;
}


.ion-color-sky {
  --ion-color-base: var(--ion-color-sky);
  --ion-color-base-rgb: var(--ion-color-sky-rgb);
  --ion-color-contrast: var(--ion-color-sky-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sky-contrast-rgb);
  --ion-color-shade: var(--ion-color-sky-shade);
  --ion-color-tint: var(--ion-color-sky-tint);
}

// 背景颜色
.bg-white {
  --ion-item-background: #ffffff;
  --ion-background-color: #ffffff;
  --ion-color-white: #ffffff;
  --ion-toolbar-background: #ffffff;
  --ion-color-base: #ffffff;
  background-color: #ffffff;
}

.bg-opacity {
  --ion-item-background: rgba(0, 0, 0, 0);
  --ion-background-color: rgba(0, 0, 0, 0);
  --ion-color-white: rgba(0, 0, 0, 0);
  --ion-toolbar-background: rgba(0, 0, 0, 0);
  --ion-color-base: rgba(0, 0, 0, 0);
  --background: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
}

.bg-gray {
  --ion-item-background: var(--ion-background-color, #f8f8f8) !important;
  --ion-background-color: var(--ion-background-color, #f8f8f8) !important;
  --ion-color-white: var(--ion-background-color, #f8f8f8) !important;
  --ion-toolbar-background: var(--ion-background-color, #f8f8f8) !important;
  --ion-color-base: var(--ion-background-color, #f8f8f8) !important;
  background-color: var(--ion-background-color, #f8f8f8) !important;
}

.bg-blue {
  background-color: var(--ion-color-primary, #32a3e7) !important;
}

// 宽度
.width-100 {
  width: 100%;
}

// 高度
.height-100 {
  height: 100%;
}

// 内边距
.margin-top1 {
  margin-top: 1px !important;
}

.margin-top2 {
  margin-top: .2rem !important;
}

.margin-top4 {
  margin-top: .4rem !important;
}

.margin-top6 {
  margin-top: .6rem !important;
}

.margin-top8 {
  margin-top: .8rem !important;
}

.margin-top10 {
  margin-top: 1rem !important;
}

.margin-top12 {
  margin-top: 1.2rem !important;
}

.margin-bottom1 {
  margin-bottom: 1px !important;
}

.margin-bottom4 {
  margin-bottom: .4rem !important;
}

.margin-bottom6 {
  margin-bottom: .6rem !important;
}

.margin-bottom8 {
  margin-bottom: .8rem !important;
}

.margin-bottom10 {
  margin-bottom: 1rem !important;
}

.margin-bottom12 {
  margin-bottom: 1.2rem !important;
}

.margin-bottom16 {
  margin-bottom: 1.6rem !important;
}

.margin-left4 {
  margin-left: .4rem !important;
}

.margin-left6 {
  margin-left: .6rem !important;
}

.margin-left8 {
  margin-left: .8rem !important;
}

.margin-left10 {
  margin-left: 1rem !important;
}

.margin-left12 {
  margin-left: 1.2rem !important;
}

.margin-right4 {
  margin-right: .4rem !important;
}

.margin-right6 {
  margin-right: .6rem !important;
}

.margin-right8 {
  margin-right: .8rem !important;
}

.margin-right10 {
  margin-right: 1rem !important;
}

.margin-right12 {
  margin-right: 1.2rem !important;
}

// 外边距
.padding-top0 {
  padding-top: 0 !important;
}

.padding-top4 {
  padding-top: .4rem !important;
}

.padding-top6 {
  padding-top: .6rem !important;
}

.padding-top8 {
  padding-top: .8rem !important;
}

.padding-top10 {
  padding-top: 1rem !important;
}

.padding-top12 {
  padding-top: 1.2rem !important;
}

.padding-bottom4 {
  padding-bottom: .4rem !important;
}

.padding-bottom6 {
  padding-bottom: .6rem !important;
}

.padding-bottom8 {
  padding-bottom: .8rem !important;
}

.padding-bottom10 {
  padding-bottom: 1rem !important;
}

.padding-bottom12 {
  padding-bottom: 1.2rem !important;
}

.padding-bottom16 {
  padding-bottom: 1.6rem !important;
}


.padding-left2 {
  padding-left: .2rem !important;
}

.padding-left4 {
  padding-left: .4rem !important;
}

.padding-left6 {
  padding-left: .6rem !important;
}

.padding-left8 {
  padding-left: .8rem !important;
}

.padding-left10 {
  padding-left: 1rem !important;
}

.padding-left12 {
  padding-left: 1.2rem !important;
}

.padding-right2 {
  padding-right: .2rem !important;
}

.padding-right4 {
  padding-right: .4rem !important;
}

.padding-right6 {
  padding-right: .6rem !important;
}

.padding-right8 {
  padding-right: .8rem !important;
}

.padding-right10 {
  padding-right: 1rem !important;
}

.padding-right12 {
  padding-right: 1.2rem !important;
}

.padding-right16 {
  padding-right: 1.6rem !important;
}

html {
  font-size: .625em;
  color: var(--ion-color-dark-tint);
}

// 字体大小
.font-9 {
  font-size: .9rem !important;
}

.font-10 {
  font-size: 1rem !important;
}

.font-11 {
  font-size: 1.1rem !important;
}

.font-12 {
  font-size: 1.2rem !important;
}

.font-13 {
  font-size: 1.3rem !important;
}

.font-14 {
  font-size: 1.4rem !important;
}

.font-15 {
  font-size: 1.5rem !important;
}

.font-16 {
  font-size: 1.6rem !important;
}

.font-17 {
  font-size: 1.7rem !important;
}

.font-18 {
  font-size: 1.8rem !important;
}

.font-19 {
  font-size: 1.9rem !important;
}

.font-20 {
  font-size: 2rem !important;
}

.font-21 {
  font-size: 2.1rem !important;
}

.font-22 {
  font-size: 2.2rem !important;
}

.font-24 {
  font-size: 2.4rem !important;
}

// 字体重量
.font-weight500 {
  font-weight: 500;
}

.font-weight700 {
  font-weight: 700;
}

.font-weight-normal {
  font-weight: normal;
}

ion-segment-button {
  padding: .4rem;
  border: 0;
  font-size: 16px;
}

// change the action sheet background color to white
.action-sheet-group.sc-ion-action-sheet-ios,
.action-sheet-button.sc-ion-action-sheet-ios {
  --background: #ffffff;
}

.action-sheet-cancel.sc-ion-action-sheet-ios {
  --background-selected: #ffffff;
}

.no-data {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}


.sticky-bar {
  position: sticky;
  top: 0;
  z-index: 99999;
}

.fixed-bar {
  position: fixed;
  top: 0;
  z-index: 999999999999999;
}

.sticky-bar-50 {
  position: sticky;
  top: 50px;
  z-index: 999999999999999;
}

/*让元素固定在底部 需要配合最小高度使用 最小高度每个页面都不一样 需要单独配置*/
//苹果适用
.sticky-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 999999999999999;
}

//安卓适用
.fixed-footer {
  position: fixed;
  bottom: 0;
  z-index: 999999999999999;
}

.static-footer {
  position: static;
  bottom: 0;
  z-index: 999999999999999;
}

.absolute-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 999999999999999;
}

.fixed-footer-tran {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999999999999999;
}

.sticky-footer-old {
  position: sticky;
  bottom: 0;
  z-index: 999999999999999;
}

.opacity-1 {
  opacity: 1 !important;
}

.opacity-0 {
  opacity: 0;
}

.opacity-06 {
  opacity: 0.6;
}

h3 {
  color: var(--ion-color-dark-tint);
}

// 小号modal
.small-modal {
  --width: 75%;
  --height: 55%;
  --border-radius: 8px;
}

.middle-modal {
  --width: 80%;
  --height: 60%;
  --border-radius: 6px;
  --min-height: 35rem;
}

.modal-opacity {
  --ion-item-background: rgba(0, 0, 0, 0);
  --ion-background-color: rgba(0, 0, 0, 0);
  --ion-color-white: rgba(0, 0, 0, 0);
  --ion-toolbar-background: rgba(0, 0, 0, 0);
  --ion-color-base: rgba(0, 0, 0, 0);
  --background: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
}

// qr modal
.qr-modal {
  --background: rgb(0, 0, 0, 0);
  --ion-background-color: rgb(0, 0, 0, 0);
  --width: 72%;
  --height: 80%;
  --border-radius: 6px;
}

// flex布局
.display-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// flex布局
.display-flex {
  display: flex;
  align-items: center;
}

.text-normal {
  white-space: normal;
}

// small picker modal
.small-picker {
  padding: 70vh 0 0;
}

$color-main: #32a3e7;

//下边框细线
.border-bo-e {
  border-bottom: 1px solid #eeeeee;
}

//上边框细线
.border-top-e {
  border-top: 1px solid #eeeeee;
}


//蓝色边框tab
.segment {
  width: 100%;
  margin: 8px 15px;
  background: white;
  border: 1px solid rgba(65, 157, 231, 1);
}

// img 22px
.list-img {
  width: 22px;
  height: 25px;
}

.list-avatar {
  width: 30px;
  height: 30px
}

// flex
.flex {
  display: flex;
}

// flex布局,上下左右居中对齐
.flex-aj {
  display: flex;
  align-items: center;
  justify-content: center;
}

// flex布局,上下居中对齐
.flex-a {
  display: flex;
  align-items: center;
}

//浅灰色线
.line-e {
  height: 1px;
  width: 100%;
  background: #eeeeee;
  margin: 5px 0;
}

//浅灰色线
.line-f4-8 {
  height: 8px;
  width: 100%;
  background: #f4f4f4;
  margin: 5px 0;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-17 {
  font-size: 17px;
}

.padding-5 {
  padding: 5px;
}

//左右5px
.padding-lr-5 {
  padding: 0 5px;
}

.color-3 {
  color: #333333;
}

.color-9 {
  color: #999999;
}

.margin-t-10 {
  margin-top: 10px;
}

.margin-t-5 {
  margin-top: 5px;
}

.margin-15 {
  margin: 15px;
}

.margin-10 {
  margin: 10px;
}

.margin-2 {
  margin: 2px;
}

.gray-10 {
  height: 10px;
  width: 100%;
  background: rgb(187, 185, 185);
}

.border-bot-8 {
  border-bottom: 8px solid #eeeeee
}

.border-bot-1 {
  border-bottom: 1px solid #eeeeee
}


//重写部分ion-searchbar的字体和icon样式
ion-searchbar {
  .searchbar-input-container {
    input {
      font-size: 14px !important;
    }

    .searchbar-search-icon {
      width: 17px;
      margin-left: 4px !important;
    }
  }
}

//蓝色按钮
.but-blue {
  height: 40px;
  padding: 20px;
  background: #419DE7;
  color: white;
  border-radius: 5px;
  font-size: 14px;
}

//绿色按钮
.but-green {
  height: 40px;
  padding: 20px;
  background: #30C5A4;
  color: white;
  border-radius: 5px;
  font-size: 14px;
}

//右上角小标
.right-point {
  position: absolute;
  top: 2px;
  left: 23px;
  font-weight: bold;
}

.custom-radio-zy {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #f0f0f0;

  &.selected {
    position: relative;
    border-color: var(--ion-color-primary, #419DE7);

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      left: 6px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--ion-color-primary, #419DE7);
    }
  }
}

//.amap-container img {
//  width: 20px !important;
//  height: 25px !important;
//  max-width: 22px !important;
//  max-height: 27px !important;
//}

//.amap-marker {
//  top: -10px !important;
//}
.my-custom-interface .select-interface-option {
  color: red;
  height: auto;
  contain: content;
  .alert-button-inner {
    .alert-radio-label{
      white-space: break-spaces;
    }
  }

}
